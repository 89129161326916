import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { type MouseEventHandler, useCallback, useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { GoArrowRight } from "react-icons/go";
import { MdOutlineDashboard } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";

import { AboutTheBusiness } from "../../components/risk-summary/about-the-business";
import DigInForm from "../../components/risk-summary/dig-in-form";
import { Navigation } from "../../components/risk-summary/navigation";
import { ScoreCategories } from "../../components/risk-summary/score-categories";
import { ScoreCategoriesTickers } from "../../components/risk-summary/score-categories-tickers-2";
import { TotalRiskScore } from "../../components/risk-summary/total-risk-score";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import { Button } from "../../components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { useToast } from "../../components/ui/use-toast";
import { ACCEPTED_FILE_TYPES } from "../../constants/file";
import { RiskSummaryFormSchema, riskSummaryFormSchema } from "../../constants/validations/risk-summary";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import useLayoutVersion from "../../hooks/useLayoutVersion";
import { DEFAULT_FORMAT_DATE, DEFAULT_FORMAT_TIME, DEFAULT_TIMEZONE } from "../../lib/constants";
import { dayjs } from "../../lib/dayjs";
import { classNames, compactJoin } from "../../lib/utils";
import { useUser } from "../../providers/user";
import {
  createBulkUpload,
  readBusinessScores,
  readSignUploadUrl,
  readUserBatchesHistory,
} from "../../Services/Services";
import type { ScoreCategoryKey } from "../../Services/Types";
import { getEnvironment } from "../../Utils/utility";
import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";

const QUERY_SELECTOR_BODY = "body";
const QUERY_SELECTOR_SECTION = "data-section-category-details";

type AccordionItemBatchProps = {
  batch: Awaited<ReturnType<typeof readUserBatchesHistory>>["batches"][number];
};

function AccordionItemBatch({ batch }: AccordionItemBatchProps) {
  const formattedDateTime = dayjs(batch.request_timestamp * 1000)
    .tz(DEFAULT_TIMEZONE, true)
    .local()
    .format(compactJoin([DEFAULT_FORMAT_DATE, DEFAULT_FORMAT_TIME]));

  return (
    <AccordionItem value={batch.batch_id.toString()} className="border-b-0">
      <AccordionTrigger className="gap-4 rounded-md bg-theme-background p-4 hover:no-underline">
        <span className="font-normal text-theme-text-body">
          <span className="font-bold">Batch Submitted:</span> {formattedDateTime}
        </span>

        <span className="mr-auto font-normal text-theme-text-input">
          <span className="font-bold">Source:</span> {batch.source}
        </span>
      </AccordionTrigger>

      <AccordionContent className="pb-0 pt-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-2/12">ID</TableHead>
              <TableHead className="w-5/12">Name</TableHead>
              <TableHead className="w-5/12">URL</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {batch.items.map((item) => (
              <TableRow key={item.item_id}>
                <TableCell className="font-medium">{item.item_id}</TableCell>
                <TableCell>
                  {!!item?.business_name?.length ? compactJoin(item.business_name, ", ") : <>&mdash;</>}
                </TableCell>
                <TableCell>
                  <a href={`/client?url=${item.url}`} className="text-primary">
                    {item.url}
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionContent>
    </AccordionItem>
  );
}

export function ClientPage() {
  const [activeSection, setActiveSection] = useState<ScoreCategoryKey | null>(null);
  const [tooltipId, setTooltipId] = useState<string | null>(null);

  const [shouldShowLatestUploadedBatch, setShouldShowLatestUploadedBatch] = useState<boolean>(false);
  const [isReFetchingUserBatchesHistory, setIsReFetchingUserBatchesHistory] = useState<boolean>(false);

  const { toast } = useToast();

  const [searchParams, setSearchParams] = useSearchParams();

  const [copiedText, onCopyToClipboard] = useCopyToClipboard();

  const formUrl = useForm<RiskSummaryFormSchema>({
    values: {
      url: searchParams.get("url") || "",
    },
    resolver: zodResolver(riskSummaryFormSchema),
  });

  const fileRef = formUrl.register("file");

  const {
    isLoading: isLoadingUserBatchesHistory,
    refetch: reFetchUserBatchesHistory,
    data: dataUserBatchesHistory,
  } = useQuery({
    queryKey: ["readUserBatchesHistory"],
    queryFn: async () => {
      const response = await readUserBatchesHistory();

      if (!response || !Object.keys(response).length) {
        toast({
          variant: "destructive",
          title: "Something went wrong!",
        });
      }

      return response;
    },
    gcTime: 0,
  });

  const { version } = useLayoutVersion();

  // const newLayout = version === 2 || version === 3;

  const { isLoading: isLoadingBusinessScores, data: queryData } = useQuery({
    queryKey: ["readBusinessScores", searchParams.get("url"), version],
    queryFn: async () => {
      const response = await readBusinessScores({
        url: searchParams.get("url"),
        ...((version === 2 || version === 3) && {
          api_version: "2",
        }),
      });

      if (!response || !Object.keys(response).length) {
        toast({
          variant: "destructive",
          title: "Client URL is not found",
        });
      }

      return response;
    },
    enabled: !!searchParams.get("url"),
    gcTime: 0,
  });

  // @ts-ignore
  const dataBusinessScores = queryData?.sections;

  const { claims } = useUser();

  const onSubmit = useCallback<SubmitHandler<RiskSummaryFormSchema>>(
    async (data) => {
      if (!!data.url && data.url !== searchParams.get("url")) {
        // @ts-ignore
        setSearchParams({
          url: data.url,
          ...(!!searchParams.get("v") && {
            v: searchParams.get("v"),
          }),
        });
      } else if (!!data.file?.length) {
        const file = data.file.item(0);

        if (!!file) {
          const responseSignUploadUrl = await readSignUploadUrl({
            filename: file.name,
          });

          const responseBulkUpload = await createBulkUpload({
            url: responseSignUploadUrl.url,
            file,
          });

          if (responseBulkUpload.status === 200) {
            setIsReFetchingUserBatchesHistory(true);

            formUrl.reset();

            setTimeout(async () => {
              await reFetchUserBatchesHistory();

              setShouldShowLatestUploadedBatch(true);

              setIsReFetchingUserBatchesHistory(false);
            }, 5000);
          }
        }
      }
    },
    [searchParams, setSearchParams, formUrl, reFetchUserBatchesHistory],
  );

  const onScrollToSection = useCallback<(sectionId: ScoreCategoryKey | null) => MouseEventHandler<HTMLButtonElement>>(
    (sectionId) => (event) => {
      event.preventDefault();

      const element = sectionId ? document.getElementById(sectionId) : document.querySelector(QUERY_SELECTOR_BODY);

      if (element) {
        element.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },
    [],
  );

  const onCopyLink = useCallback<
    ({ id, value }: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>
  >(
    ({ id, value }) =>
      async (event) => {
        event.preventDefault();

        await onCopyToClipboard(value);

        setTooltipId(id);

        setTimeout(() => setTooltipId(null), 3000);
      },
    [onCopyToClipboard],
  );

  useEffect(() => {
    const scrollListener = () => {
      let activeSectionId: ScoreCategoryKey | null = null;

      document.querySelectorAll(`[${QUERY_SELECTOR_SECTION}]`).forEach((section) => {
        const sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= 160) {
          activeSectionId = section.id as typeof activeSectionId;
        }
      });

      setActiveSection(activeSectionId);
    };

    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  const hasBusinessScoresData = !!dataBusinessScores && !!Object.keys(dataBusinessScores).length;

  // const hasDataSectionAboutTheBusiness =
  //   !!dataBusinessScores?.v2?.about && !!Object.keys(dataBusinessScores.v2.about).length;

  const latestUploadedBatch = !!dataUserBatchesHistory?.batches?.length
    ? shouldShowLatestUploadedBatch && [...dataUserBatchesHistory.batches].shift()
    : null;

  const allBatches = !!dataUserBatchesHistory?.batches?.length
    ? shouldShowLatestUploadedBatch
      ? [...dataUserBatchesHistory.batches].slice(1, dataUserBatchesHistory.batches.length)
      : dataUserBatchesHistory.batches
    : [];

  const [expandedDetailsBlocks, setExpandedDetailsBlocks] = useState<Record<string, boolean>>({});

  const onToggleExpandedDetailsBlock = useCallback<(key: string) => void>((key) => {
    setExpandedDetailsBlocks((previous) => ({
      ...previous,
      [key]: !previous[key],
    }));
  }, []);

  const isGroupedDetailsExpanded = useCallback<(key: string) => boolean>(
    (key) => !!expandedDetailsBlocks[key],
    [expandedDetailsBlocks],
  );

  const environment = getEnvironment();

  if (!searchParams.get("url") && !hasBusinessScoresData) {
    return (
      <div className="grid min-h-screen grid-rows-[auto,1fr,auto]">
        <PageHeader colorTheme="blue" />

        <main className="size-full px-4 py-24">
          {!searchParams.get("newForm") ? (
            <Form {...formUrl}>
              <form
                className="mx-auto grid w-full max-w-2xl gap-6 rounded-2xl border border-theme-border bg-white px-24 py-12"
                onSubmit={formUrl.handleSubmit(onSubmit)}
              >
                <h1 className="mb-2 text-center text-2xl font-bold text-theme-text-body">
                  Calculate the risks for your business
                </h1>

                <p className="text-base font-normal text-theme-text-body">
                  Just type the client URL and we will provide scores and evidence you can immediately use for
                  automating risk decisions or augmenting manual review.
                </p>

                <FormField
                  name="url"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="Type the client URL (e.g. https://bluearc.ai)" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="submit"
                  disabled={formUrl.formState.isSubmitting || isLoadingBusinessScores || !formUrl.formState.isValid}
                >
                  Submit
                  {formUrl.formState.isSubmitting || isLoadingBusinessScores ? (
                    <CgSpinner className="ml-3 size-5 animate-spin" />
                  ) : (
                    <GoArrowRight className="ml-3 size-5" />
                  )}
                </Button>
              </form>
            </Form>
          ) : (
            <div className="mx-auto grid w-full max-w-4xl gap-6 rounded-2xl border border-theme-border bg-white px-24 py-12">
              <h1 className="mb-2 text-center text-2xl font-bold text-theme-text-body">
                Calculate the risks for your business
              </h1>

              <Form {...formUrl}>
                <form className="grid w-full gap-6" onSubmit={formUrl.handleSubmit(onSubmit)}>
                  <div className="grid items-center gap-x-6 gap-y-2 md:grid-cols-[1fr,auto,1fr]">
                    <p className="text-base font-normal text-theme-text-body">Type the client URL:</p>

                    <FormField
                      name="url"
                      control={formUrl.control}
                      render={({ field }) => (
                        <FormItem className="md:row-start-2">
                          <FormControl>
                            <Input placeholder="https://bluearc.ai" {...field} />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div className="my-4 flex items-center md:col-start-2 md:row-start-2 md:my-0">
                      <span className="grow border-b border-theme-border" />
                      <span className="px-5 text-sm font-medium text-theme-text-input md:px-0">OR</span>
                      <span className="grow border-b border-theme-border" />
                    </div>

                    <p className="text-base font-normal text-theme-text-body md:col-start-3">Upload the CSV file:</p>

                    <FormField
                      name="file"
                      control={formUrl.control}
                      render={() => (
                        <FormItem className="md:row-start-2">
                          <FormControl>
                            <Input
                              type="file"
                              placeholder="Upload the CSV file"
                              accept={compactJoin(ACCEPTED_FILE_TYPES, ",")}
                              {...fileRef}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <p className="text-base font-normal text-theme-text-body">
                    We will provide scores and evidence you can immediately use for automating risk decisions or
                    augmenting manual review.
                  </p>

                  <Button
                    type="submit"
                    className="w-full md:max-w-xs md:justify-self-center"
                    disabled={
                      formUrl.formState.isSubmitting ||
                      isLoadingBusinessScores ||
                      isReFetchingUserBatchesHistory ||
                      !formUrl.formState.isValid
                    }
                  >
                    Submit
                    {formUrl.formState.isSubmitting || isLoadingBusinessScores || isReFetchingUserBatchesHistory ? (
                      <CgSpinner className="ml-3 size-5 animate-spin" />
                    ) : (
                      <GoArrowRight className="ml-3 size-5" />
                    )}
                  </Button>
                </form>
              </Form>

              {!!latestUploadedBatch && (
                <div className="grid w-full rounded-2xl border border-theme-border p-4">
                  <Accordion type="single" collapsible={true} defaultValue={latestUploadedBatch.batch_id.toString()}>
                    <AccordionItemBatch batch={latestUploadedBatch} />
                  </Accordion>
                </div>
              )}

              <div className="grid w-full gap-4 rounded-2xl border border-theme-border p-4">
                <h2 className="text-xl font-bold text-theme-text-body">Batches History</h2>

                {isLoadingUserBatchesHistory ? (
                  <CgSpinner
                    className="my-4 size-10 animate-spin justify-self-center text-primary"
                    aria-label="Loading..."
                  />
                ) : !!allBatches?.length ? (
                  <Accordion type="multiple" className="contents">
                    {allBatches.map((batch) => (
                      <AccordionItemBatch key={batch.batch_id} batch={batch} />
                    ))}
                  </Accordion>
                ) : (
                  <p className="my-4 text-center text-sm font-normal text-theme-text-input">No data to display!</p>
                )}
              </div>
            </div>
          )}
        </main>

        <PageFooter displayContact={false} />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "grid min-h-screen",
        hasBusinessScoresData ? "grid-rows-[auto,auto,1fr,auto]" : "grid-rows-[auto,1fr,auto]",
      )}
    >
      <PageHeader colorTheme="blue" />

      {hasBusinessScoresData && (
        <Navigation
          activeSection={activeSection}
          // @ts-ignore
          onScrollToSection={onScrollToSection}
          dataBusinessScores={dataBusinessScores}
        />
      )}

      <main className={classNames("size-full px-4 pb-14", !hasBusinessScoresData && "pt-14")}>
        <div className="mx-auto grid w-full max-w-6xl gap-10">
          <div className="grid gap-5 md:grid-cols-2 md:gap-y-10">
            <h1 className="text-3xl font-bold text-theme-text-body md:my-0.5">Risk Summary</h1>

            <Form {...formUrl}>
              <form className="grid grid-cols-[1fr,auto] gap-2" onSubmit={formUrl.handleSubmit(onSubmit)}>
                <FormField
                  name="url"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="https://bluearc.ai" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <div className="flex items-center gap-1">
                  <Button type="submit" disabled={formUrl.formState.isSubmitting || isLoadingBusinessScores}>
                    Submit
                    {formUrl.formState.isSubmitting || isLoadingBusinessScores ? (
                      <CgSpinner className="ml-3 size-5 animate-spin" />
                    ) : (
                      <GoArrowRight className="ml-3 size-5" />
                    )}
                  </Button>

                  {!!claims?.admin && (
                    <Link to={`/dashboard?url=${searchParams.get("url")}`}>
                      <Button>
                        <MdOutlineDashboard className="size-5" />
                      </Button>
                    </Link>
                  )}
                </div>
              </form>
            </Form>

            {hasBusinessScoresData && (
              <>
                {version === 3 && (
                  <>
                    <div />
                    <DigInForm />
                  </>
                )}

                {
                  // @ts-ignore
                  queryData?.about_the_business && (
                    <AboutTheBusiness
                      dataBusinessScores={{
                        v2: {
                          // @ts-ignore
                          about: {
                            // @ts-ignore
                            url: queryData?.about_the_business?.business_url,
                            // @ts-ignore
                            summary: queryData?.about_the_business?.description,
                            // name: queryData?.about_the_business.,
                          },
                        },
                      }}
                    />
                  )
                }

                <TotalRiskScore
                  dataBusinessScores={{
                    v2: {
                      // @ts-ignore
                      total_risk_score: {
                        // @ts-ignore
                        overall_score: queryData?.overall_risk_score?.score,
                      },
                    },
                  }}
                />

                <h2 className="text-2xl font-bold text-theme-text-body md:col-span-2">Detailed Score</h2>

                <ScoreCategoriesTickers
                  // @ts-ignore
                  tickers={queryData?.detailed_risk_scores}
                  onScrollToSection={onScrollToSection}
                />

                {environment === "local" && (
                  <div className="relative grid scroll-mt-40 content-start gap-8 rounded-2xl border bg-white p-8 md:col-span-2">
                    <h2 className="flex items-center justify-between text-2xl font-bold text-theme-text-body md:col-span-2">
                      <span>View process steps details</span>
                      <a
                        href={`/process-steps?url=${searchParams.get("url")}`}
                        className="text-[12px] font-[700] leading-[18px] text-primary"
                      >
                        <GoArrowRight className="ml-3 size-10" />
                      </a>
                    </h2>
                  </div>
                )}
                <ScoreCategories
                  tooltipId={tooltipId as string}
                  onCopyLink={onCopyLink}
                  copiedText={copiedText as string}
                  dataBusinessScores={dataBusinessScores}
                  isGroupedDetailsExpanded={isGroupedDetailsExpanded}
                  onToggleExpandedDetailsBlock={onToggleExpandedDetailsBlock}
                />
              </>
            )}
          </div>
        </div>
      </main>

      <PageFooter displayContact={false} />
    </div>
  );
}

import { type ClassValue, clsx } from "clsx";
import DOMPurify from "dompurify";
import lowerCase from "lodash/lowerCase";
import startCase from "lodash/startCase";
import { twMerge } from "tailwind-merge";

export function classNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// https://www.30secondsofcode.org/js/s/compact-join-array
export function compactJoin(array: unknown[], separator = " ") {
  return array.filter(Boolean).join(separator);
}

export function isUrl(value: string): boolean {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
}

export function ensureHttps(url: string): string {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }

  return url;
}

export function getHostnameFromURL(url: string, limit: number = 80): string {
  try {
    const hostname = new URL(url).hostname;

    // in case some string got parsed as URL without hostname
    if (!hostname) {
      return url;
    }

    return hostname;
  } catch {
    if (url?.length > limit) {
      return url?.substring(0, limit) + "...";
    } else {
      return url;
    }
  }
}

export function formatSectionName(sectionName: string): string {
  return startCase(lowerCase(sectionName))?.replace("And", "&");
}

export function sanitizeHTML(html: string): string {
  const sanitizedHTML = DOMPurify.sanitize(html, {
    /**
     * Here you can find tags convention we use:
     * https://docs.google.com/document/d/1whhRE-gceU5kC3pKO4wKS1hpxzIRTrxttbMo22LLVGI/edit?tab=t.0
     */
    ALLOWED_TAGS: ["b", "i", "u", "br", "table", "tr", "td", "a"],
  });

  return sanitizedHTML;
}

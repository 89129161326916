import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { Toaster } from "./components/ui/toaster";
import { ChatPage } from "./pages/Chat";
import ClientPage from "./pages/client/factory";
import Dashboard from "./pages/Dashboard";
import APIErrorPage from "./pages/Error/APIError";
import NotFoundErrorPage from "./pages/Error/NotFoundError";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Layout from "./pages/Layout/layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProcessSteps from "./pages/ProcessSteps";
import RegVouch from "./pages/RegVouch";
import RunProcess from "./pages/RunProcess";
import RunUrlProcessing from "./pages/RunUrlProcessing";
import TermsOfUse from "./pages/TermsOfUse";
import ThankYouPage from "./pages/ThankYouPage";
import { AuthProvider } from "./providers/auth";
import { UserProvider } from "./providers/user";
import { verifyToken } from "./Services/Services";
import { buildApiURLQuery, getEnvironment } from "./Utils/utility";

const App = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const [response, setResponse] = useState<unknown | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authToken: string | null = queryParams.get("token_id") || sessionStorage.getItem("token_id");
  sessionStorage.setItem("token_id", authToken as string);
  const environment = getEnvironment();

  const onLoad = async () => {
    try {
      const token = queryParams.get("token_id");

      if (token || location?.pathname?.indexOf("layout") >= 0 || location?.pathname?.indexOf("thankyoupage") >= 0) {
        const query = buildApiURLQuery({
          token_id: authToken,
        });
        const response = await verifyToken({ queryParam: query });

        setResponse(response);

        if (response && response.status === 200) {
          navigate("/layout");
        } else if (response && response.status === 400) {
          navigate("/api-error");
        }
      }
    } catch (ex) {
      console.error("Error:", ex);
      navigate("/not-found-error");
    }
  };

  useEffect(
    () => {
      onLoad();
    },
    // ToDo: Should be well tested.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/layout" element={<Layout apiResponse={response} />} />
        <Route path="/thankyoupage/:option" element={<ThankYouPage apiResponse={response} option={""} />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/api-error" element={<APIErrorPage />} />
        <Route path="/not-found-error" element={<NotFoundErrorPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route
          path="/client"
          element={
            <UserProvider>
              <AuthProvider>
                <ClientPage />
              </AuthProvider>
            </UserProvider>
          }
        />
        {(environment === "qa" || environment === "local") && (
          <Route
            path="/process-steps"
            element={
              <AuthProvider>
                <ProcessSteps />
              </AuthProvider>
            }
          />
        )}
        {(environment === "qa" || environment === "local") && (
          <Route
            path="/run-process"
            element={
              <AuthProvider>
                <RunProcess />
              </AuthProvider>
            }
          />
        )}
        <Route
          path="/dashboard"
          element={
            <UserProvider>
              <AuthProvider requireAdmin>
                <Dashboard />
              </AuthProvider>
            </UserProvider>
          }
        />
        <Route
          path="/run-url-processing"
          element={
            <AuthProvider>
              <RunUrlProcessing />
            </AuthProvider>
          }
        />
        <Route path="/email_template/reg-vouch/:token/:action" element={<RegVouch />} />
        <Route path="*" element={<NotFoundErrorPage />} />
      </Routes>

      <Toaster />
    </QueryClientProvider>
  );
};

export default App;
